import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: "app",
  initialState: {
    allData: {
      userId: "",
      entityId: "",
      entityName: "",
      userName: "",
      email: "",
      role: "",
    },
  },
  reducers: {
    setAppData(state, action) {
      state.allData = action.payload;
    },
  },
});

export const appActions = appSlice.actions;

export default appSlice.reducer;
