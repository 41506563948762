import Header from "pages/Header/Header";
import NavigationMenu from "pages/navigation-menu/index";
import style from "./assets/css/Layout.module.css";
import { uiActions } from "../store/ui/ui-slice";
import { useDispatch, useSelector } from "react-redux";

interface IProps {
  children: any;
}

const Layout = ({ children }: IProps) => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    // dispatch(uiActions.toggleNavigationMenu());

    console.log("Logging out...");
  };

  return (
    <div className={style.layout}>
      <Header handleLogout={handleLogout} />
      <NavigationMenu />
      <div className={style.content}>{children}</div>
    </div>
  );
};

export default Layout;
