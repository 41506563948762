import React from "react";
// mui
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
// components
import FormProvider from "../hook-form/form-provider";
import RHFTextField from "../hook-form/rhf-text-field";
// routing
import { useNavigate } from "react-router-dom";
// form
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
// redux
import { useSelector, useDispatch } from "react-redux";
import { uiActions } from "../../store/ui/ui-slice";
//utils
import axiosInstance from "utils/axios";
import { IApp } from "../../types/IApp";
enum Role {
  Admin = "admin",
  User = "user",
}

const Verify = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const app = useSelector((state: any) => state.app.allData);

  const RegisterSchema = Yup.object().shape({
    verification_code: Yup.string().required("ication code is required").min(7),
  });
  const defaultValues = {
    verification_code: "",
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });
  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      const response = await axiosInstance.post("api/verify/", {
        verification_code: data.verification_code.toUpperCase(),
        userId: app.userId,
      });
      const { isOk, message, role } = response.data;
      if (isOk) {
        if (role === Role.Admin) {
          navigate("/Admin");
        } else if (role === Role.User) {
          navigate("/User");
        }
      } else {
        dispatch(
          uiActions.updateNotification({
            message: message,
            level: "error",
          })
        );
      }
    } catch (error: any) {
      console.error(error);
      reset();
      dispatch(
        uiActions.updateNotification({
          message: error.message,
          level: "error",
        })
      );
    }
  });
  return (
    <div
      style={{
        width: "50vw",
        position: "absolute",
        left: "25%",
        display: "flex",
        flexDirection: "column",
        height: "50vh",
        justifyContent: "space-around",
      }}
    >
      <h3 style={{ alignSelf: "center", justifySelf: "flex-start" }}>
        Thanks for Joining Time Tracker
      </h3>
      <h5>Please enter the verification code</h5>

      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Stack spacing={2.5}>
          <RHFTextField
            name="verification_code"
            label="XXX-XXX"
            autoComplete="off"
          />

          <LoadingButton
            fullWidth
            color="primary"
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Verify
          </LoadingButton>
        </Stack>
      </FormProvider>
    </div>
  );
};

export default Verify;
