import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { appActions } from "../../store/app/app";
import myicon from "./assets/icon/logout (1).png";
import styles from "./assets/css/Header.module.css";
import MenuIcon from "./assets/icon/menu (2).png";
import { uiActions } from "../../store/ui/ui-slice";

interface HeaderProps {
  handleLogout: () => void;
}

const Header: React.FC<HeaderProps> = ({ handleLogout }) => {
  const app = useSelector((state: any) => state.app.allData);
  const dispatch = useDispatch();

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.clear();
    dispatch(uiActions.setNavigationMenu({ open: false }));

    dispatch(
      appActions.setAppData({
        userId: "",
        entityId: "",
        entityName: "",
        userName: "",
        email: "",
      })
    );
    handleLogout();
  };

  const _MenuClickHandler = () => {
    dispatch(uiActions.toggleNavigationMenu());
  };

  return (
    <Navbar className={styles.nav} variant="dark" expand="lg">
      <Navbar.Brand href="/" className={styles.a}>
        TickTrackIT
      </Navbar.Brand>

      <Nav className="mr-auto"></Nav>
      <span className={`mr-auto ${styles.wel}`}>
        {app && app.userName ? `${app.entityName}` : ""}
      </span>

      <div className={styles.rightContainer}>
        <Link className={`${styles.link}`} to="/FAQ">
          FAQ
        </Link>
        {app && app.userId ? (
          <Link className={`${styles.link}`} to="/Login" onClick={logout}>
            <img
              src={myicon}
              alt="Logout Icon"
              style={{ width: 30, height: 30, marginRight: "8px" }}
            />
          </Link>
        ) : (
          <Link className={`${styles.link}`} to="/Login">
            Login
          </Link>
        )}
        {app && app.userId && (
          <div className={`${styles.menu}`} onClick={_MenuClickHandler}>
            <img
              src={MenuIcon}
              alt="Menu Icon"
              style={{ width: 30, height: 30 }}
            />
          </div>
        )}
      </div>
    </Navbar>
  );
};

export default Header;
