import { useState, useEffect } from "react";
import { BrowserRouter, useRoutes, Navigate } from "react-router-dom";
import Layout from "./Layout/Layout";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import Report from "./pages/Report/Report";
import Verify from "./component/verify/verify";
import Initializer from "./global/Initializer";
import MySnackbar from "./component/snakbar/snakbar";
import { Provider } from "react-redux";
import DotLottie from "../src/intro/dot-lottie/dot-lottie";
import styles from "./App.module.css";
import IntroAnimation from "../src/intro/Animation - 1717500961843 (1).json";
import store from "./store/index";
import Edit from "./pages/Edit/Edit";
import All from "./pages/AllProjects/AllProjects";
import QueryProjects from "./pages/QueryProjects/QueryProjects";
import ReportUser from "./pages/ReportUser/ReportUser";
import FAQ from "./pages/FAQ/Faq";
import EditUsers from "./pages/EditUsers/EditUsers";
import AllUsers from "./pages/AllUsers/AllUsers";
import TimeTracking from "./pages/TimeTracking/time-tracking";
import Approve from "./pages/Assign Project To Member/AssignProjectToMember";
import Approvetime from "./pages/Approve/Approve";
import CreateProject from "./pages/CreatPro/CreateProject";
import CreateUsers from "./pages/CreateUsers/CreateUsers";
import OnlineUser from "./pages/OnlineUser/OnlineUser";
import Profile from "./pages/Profile/Profile";
import Reset from "./pages/Reset/Reset";
import QueryProjectsUser from "./pages/QueryProjjectsUser/QueryProjectsUser";
import ManualTracker from "./pages/TimeTracking/time-tracking-manual";
import TimeTrackingData from "./pages/TimeTrackingData/TimeTrackingData";
import WelcomeScreen from "./pages/welcome-screen/welcome-screen";

function App() {
  const [showIntroGif, setShowIntroGif] = useState(false);
  const [terminateIntro, setTerminateIntro] = useState(false);

  useEffect(() => {
    const introShown = localStorage.getItem("introShown");

    if (introShown !== "true") {
      setShowIntroGif(true);
      localStorage.setItem("introShown", "true");
    }
  }, []);

  useEffect(() => {
    if (!showIntroGif) return;

    const timer = setTimeout(() => {
      setShowIntroGif(false);
    }, 4250);

    return () => clearTimeout(timer);
  }, [showIntroGif]);

  useEffect(() => {
    if (!showIntroGif) {
      const introDiv = document.querySelector(".intro");
      if (introDiv) {
        introDiv.classList.add(styles.introDivFade);
      }
      const timer = setTimeout(() => {
        setTerminateIntro(true);
      }, 750);
      return () => clearTimeout(timer);
    }
  }, [showIntroGif]);

  // Protected Route pages
  const ProtectedRoute = ({ children }: any) => {
    const token = localStorage.getItem("token");
    if (!token) {
      return <Navigate to="/Login" />;
    }
    return children;
  };

  function Router() {
    const routes = [
      { protected: false, path: "/User", element: <WelcomeScreen /> },
      { protected: false, path: "/Admin", element: <WelcomeScreen /> },

      { protected: false, path: "/Login", element: <Login /> },
      { protected: false, path: "/Register", element: <Register /> },
      { protected: false, path: "/FAQ", element: <FAQ /> },
      { protected: false, path: "/verify", element: <Verify /> },
      {
        protected: true,
        path: "/User/ManualTracker",
        element: <ManualTracker />,
      },
      { protected: true, path: "/User/Profile", element: <Profile /> },
      { protected: true, path: "User/Tracker", element: <TimeTracking /> },
      { protected: true, path: "/User/MyReport", element: <ReportUser /> },
      {
        protected: true,
        path: "/User/MyReports",
        element: <QueryProjectsUser />,
      },
      {
        protected: true,
        path: "/Admin/ManualTracker",
        element: <ManualTracker />,
      },
      { protected: true, path: "/Admin/edit-project", element: <Edit /> },
      { protected: true, path: "/Admin/All", element: <All /> },
      { protected: true, path: "/Admin/AllUsers", element: <AllUsers /> },
      {
        protected: true,
        path: "/Admin/UserReport",
        element: <QueryProjects />,
      },
      {
        protected: true,
        path: "/Admin/ProjectReport",
        element: <TimeTrackingData />,
      },
      { protected: true, path: "/Admin/edit-member", element: <EditUsers /> },
      { protected: true, path: "/Admin/Approve", element: <Approvetime /> },
      { protected: true, path: "/Admin/AssignProject", element: <Approve /> },
      {
        protected: true,
        path: "/Admin/CreateProject",
        element: <CreateProject />,
      },
      { protected: true, path: "/Admin/CreateUser", element: <CreateUsers /> },
      { protected: true, path: "/Admin/OnlineUser", element: <OnlineUser /> },
      { protected: true, path: "/Admin/Profile", element: <Profile /> },
      { protected: true, path: "/Admin/Tracker", element: <TimeTracking /> },
      { protected: true, path: "/Admin/Reset", element: <Reset /> },
      { protected: true, path: "/Admin/Report", element: <Report /> },
    ];

    const routing = useRoutes(
      routes.map((x) => {
        return {
          ...x,
          element: x.protected ? (
            <ProtectedRoute>
              <Layout>{x.element}</Layout>
            </ProtectedRoute>
          ) : (
            <Layout>{x.element}</Layout>
          ),
        };
      })
    );

    return routing;
  }

  return (
    <>
      {!terminateIntro && showIntroGif ? (
        <div className={`${styles.introDiv} intro`}>
          <DotLottie animation={IntroAnimation} />
          <div className={styles.logo}></div>
          <div className={styles.tit}>TickTrackIT</div>
        </div>
      ) : (
        <Provider store={store}>
          <BrowserRouter>
            <Initializer>
              <Router />
              <MySnackbar />
            </Initializer>
          </BrowserRouter>
        </Provider>
      )}
    </>
  );
}

export default App;
